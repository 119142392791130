<template>
  <!-- 首页 - 顶部广告 - 报名页 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goBack">岗位登记</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">我要招聘</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3>岗位要求</h3>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="140px"
              class="demo-ruleForm"
              size="small"
              >

              <el-form-item label="1.招聘岗位" prop="positionName">
                <el-input v-model="ruleForm.positionName" size="small" placeholder="请输入招聘岗位" @focus="inputpositio"></el-input>
              </el-form-item>
              <el-form-item label="2.招聘人数" prop="recruitmentNum">
                <el-input
                    v-model="ruleForm.recruitmentNum"
                    placeholder="请输入招聘人数"
                    @keyup.native="
                    ruleForm.recruitmentNum = zF.positiveInteger(
                      ruleForm.recruitmentNum
                    )
                  "
                    :disabled="type == 'examine'||type == 'view'"
                ></el-input>
              </el-form-item>
              <el-form-item label="3.工作地点" prop="areaId">
                <el-cascader
                  :props="{ emitPath: false, checkStrictly: true }"
                  v-model="ruleForm.areaId"
                  :options="cityTree"
                  @change="handleCascader"
                  clearable
                  placeholder="请选择工作地点"
                  :disabled="type == 'examine'||type == 'view'"
                ></el-cascader>
              </el-form-item>

              <el-form-item label="4.薪酬范围" prop="salaryRange">
                <el-select

                    v-model="ruleForm.salaryRange"
                    placeholder="请选择薪酬范围"
                    size="small"
                    :disabled="type == 'examine'||type == 'view'"
                >
                  <el-option
                      v-for="item in salaryRangeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  ></el-option>
                </el-select>
<!--                {{ ruleForm.salaryRange }}-->
              </el-form-item>
              <el-form-item label="5.岗位职责/要求" prop="postResponsibility">
                <el-input
                  type="textarea"
                  v-model="ruleForm.postResponsibility"
                  placeholder="请输入岗位职责/要求"
                  maxlength="500"
                  show-word-limit
                  :autosize="{ minRows: 4, maxRows: 6}"
                  :disabled="type == 'examine'||type == 'view'"
                ></el-input>
              </el-form-item>

              <el-form-item label="6.企业名称" prop="compName">
                <el-input
                    v-model="ruleForm.compName"
                    placeholder="请输入企业名称"
                    maxlength="50"
                    show-word-limit
                    :disabled="type == 'examine'||type == 'view'"
                ></el-input>
              </el-form-item>

              <el-form-item label="7.联系人" prop="contactName">
                <el-input
                    v-model="ruleForm.contactName"
                    placeholder="请输入联系人"
                    maxlength="20"
                    show-word-limit
                    :disabled="type == 'examine'||type == 'view'"
                ></el-input>
              </el-form-item>
              <el-form-item label="8.联系方式" prop="contactMobile">
                <el-input
                    v-model="ruleForm.contactMobile"
                    placeholder="请输入联系方式"
                    :disabled="type == 'examine'||type == 'view'"
                ></el-input>
              </el-form-item>

              <el-form-item label="9.职位有效期" prop="jobValidityPeriod">
                <el-select

                  v-model="ruleForm.jobValidityPeriod"
                  placeholder="请选择职位有效期"
                  size="small"
                  :disabled="type == 'examine'||type == 'view'"
                >
                  <el-option
                    v-for="item in jobValidityPeriodList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
<!--                {{ ruleForm.jobValidityPeriod }}-->
              </el-form-item>
              <el-form-item label="10.到岗时间" prop="arrivalTime">
                <el-select

                  v-model="ruleForm.arrivalTime"
                  placeholder="请选择到岗时间"
                  size="small"
                  :disabled="type == 'examine'||type == 'view'"
                >
                  <el-option
                    v-for="item in arrivalTimeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
<!--                {{ ruleForm.dutyTime }}-->
              </el-form-item>
              <el-form-item label="11.学历要求" prop="education">
                <el-select

                  v-model="ruleForm.education"
                  placeholder="请选择学历要求"
                  size="small"
                  @clear="ruleForm.education == '10'"
                  :disabled="type == 'examine'||type == 'view'"
                >
                  <el-option
                    v-for="item in educationList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="12.工作经验" prop="workExperience">
                <el-select

                  v-model="ruleForm.workExperience"
                  placeholder="请选择工作经验"
                  size="small"
                  :disabled="type == 'examine'||type == 'view'"
                  @clear="ruleForm.workExperience == 10"
                >
                  <el-option
                    v-for="item in workExperienceList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
<!--                {{ ruleForm.workExperience }}-->
              </el-form-item>

              <el-form-item label="13.年龄要求" prop="ageRequirement">
                <el-select

                    v-model="ruleForm.ageRequirement"
                    placeholder="请选择工作经验"
                    size="small"
                    @clear="ruleForm.ageRequirement == '50'"
                    :disabled="type == 'examine'||type == 'view'"
                >
                  <el-option
                      v-for="item in ageRequirementList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  ></el-option>
                </el-select>
<!--                {{ ruleForm.ageRequirement }}-->
<!--                <el-radio-group v-model="ruleForm.ageRequirement">-->
<!--                  <el-radio-->
<!--                    v-for="item in ageRequirementList"-->
<!--                    :key="item.id"-->
<!--                    :label="item.id"-->
<!--                  >-->
<!--                    {{ item.name }}-->
<!--                  </el-radio>-->
<!--                </el-radio-group>-->
              </el-form-item>
              <el-form-item label="14.性别要求" prop="sex">
                <el-select

                    v-model="ruleForm.sex"
                    placeholder="请选择工作经验"
                    size="small"
                    @clear="ruleForm.sex == '3'"
                    :disabled="type == 'examine'||type == 'view'"
                >
                  <el-option
                      v-for="item in sexList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  ></el-option>
                </el-select>
<!--                {{ ruleForm.sex }}-->
              </el-form-item>
              <el-form-item label="15.福利待遇" prop="welfareTreatment">
                <el-select
                    v-model="ruleForm.welfareTreatment"
                    multiple
                    placeholder="请选择"
                    filterable
                    :multiple-limit="5"
                    :disabled="type == 'examine'||type == 'view'"
                >
                  <el-option
                      v-for="item in welfareTreatmentList"
                      :key="item.recruitmentLabelId"
                      :label="item.labelName"
                      :value="item.recruitmentLabelId"
                      >
                    <span class="colorPoint" :style="'background-color: '+item.colour"></span>
                    <span class="fl">{{ item.labelName }}</span>
                  </el-option>
                </el-select>
<!--                <el-input-->
<!--                    type="textarea"-->
<!--                    v-model="ruleForm.welfareTreatment"-->
<!--                    placeholder="请输入岗位职责/要求"-->
<!--                    maxlength="200"-->
<!--                    :autosize="{ minRows: 4, maxRows: 6}"-->
<!--                    show-word-limit-->
<!--                    :disabled="type == 'examine'||type == 'view'"-->
<!--                ></el-input>-->
              </el-form-item>

              <el-divider v-if="type == 'examine'"></el-divider>
              <el-form-item label="驳回理由" prop="auditReason" v-if="type == 'examine'||(type == 'view'&&ruleForm.auditReason)">
                <el-input
                    type="textarea"
                    v-model="ruleForm.auditReason"
                    placeholder="请输入驳回理由"
                    maxlength="200"
                    :autosize="{ minRows: 4, maxRows: 6}"
                    :disabled="type == 'view'&&ruleForm.auditReason"
                    show-word-limit
                ></el-input>
              </el-form-item>

              <el-form-item style="text-align: center;">
                <el-button type="primary" @click="submitForm('ruleForm')" v-if="type != 'examine'&&type != 'view'"
                  >确认{{type == 'add'?'提交':type == 'edit'?'修改':'提交'}}</el-button
                >
                <el-button type="primary" v-if="type == 'examine'" @click="handlerAduit('30')"
                >审核通过</el-button
                >
                <el-button type="error" v-if="type == 'examine'" @click="handlerAduit('20')"
                >审核不通过</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 期望职位 -->
    <el-dialog title="期望职位" :visible.sync="previewLoding" width="40%" top="5%" center>
      <div class="zjzw">
        <div>
          <div
              :class="previewLodingData_1_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_1"
              :key="index"
              @click="selectpreview(1,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
        <div>
          <div
              :class="previewLodingData_2_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_2"
              :key="index"
              @click="selectpreview(2,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
        <div>
          <div
              :class="previewLodingData_3_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_3"
              :key="index"
              @click="selectpreview(3,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
      </div>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import {resetKeepAlive} from "@/utils/common";
import {mapGetters} from "vuex";

export default {
  name: "accountManagement_advertisementInfo",
  components: {},
  data() {
    return {
      // 基本信息
      ruleForm: {
        // 工作地点
        areaId: "",
        // 招聘岗位
        positionId: "",
        positionName: "",
        // 招聘人数
        recruitmentNum: "",
        // 岗位职责/要求
        postResponsibility: "",
        // 职位有效期
        jobValidityPeriod:"60",
        // 到岗时间
        arrivalTime:"10",
        // 学历要求
        education: "100",
        // 工作经验
        workExperience: "10",
        // 薪酬范围
        salaryRange: "10",
        // 年龄要求
        ageRequirement: "50",
        // 联系人
        contactName: "",
        // 联系方式
        contactMobile: "",
        // 企业名称
        compName:"",
        // 性别要求
        sex:"3",
        // 福利待遇
        welfareTreatment:"",
        // 驳回理由
        auditReason: '',

      },
      // 行政区划 - 下拉数据
      cityTree: [],
      // 职位有效期 - 下拉数据
      jobValidityPeriodList: [],
      // 到岗时间 - 下拉数据
      arrivalTimeList: [],
      // 学历 - 下拉数据
      educationList: [],
      // 工作经验 - 下拉数据
      workExperienceList: [],
      // 薪酬范围 - 下拉数据
      salaryRangeList: [],
      // 年龄要求 - 下拉数据
      ageRequirementList: [],
      // 性别要求 - 下拉数据
      sexList: [],
      // 福利待遇 - 下拉数据
      welfareTreatmentList: [],
      // 基本信息 - 校验
      rules: {
        // 工作地点
        areaId: [
          { required: true, message: "请选择工作地点", trigger: "change" },
        ],
        // 招聘岗位
        positionName: [{ required: true, message: "请输入招聘岗位", trigger: "blur" }],
        // 招聘人数
        recruitmentNum: [
          { required: true, message: "请输入招聘人数", trigger: "blur" },
        ],
        // 岗位职责/要求
        postResponsibility: [
          { required: true, message: "请输入岗位职责/要求", trigger: "blur" },
        ],
        // 职位有效期
        // jobValidityPeriod: [
        //   { required: true, message: "请选择职位有效期", trigger: "change" },
        // ],
        // // 到岗时间
        // dutyTime: [
        //   { required: true, message: "请选择到岗时间", trigger: "change" },
        // ],
        // 学历要求
        // education: [
        //   { required: true, message: "请选择学历要求", trigger: "change" },
        // ],
        // // 工作经验
        // workExperience: [
        //   { required: true, message: "请选择工作经验", trigger: "change" },
        // ],
        // 薪酬范围
        salaryRange: [
          { required: true, message: "请选择薪酬范围", trigger: "change" },
        ],
        // // 年龄要求
        // ageRequirement: [
        //   { required: true, message: "请选择年龄要求", trigger: "change" },
        // ],
        // 联系人
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        // 联系方式
        contactMobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        // 企业名称
        compName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        // auditReason: [
        //   { required: true, message: "请输入驳回理由", trigger: "blur" },
        // ],

      },
      // 从哪个按钮进来的add examine
      type: '',
      row: {},
      
      // 期望职位 - 弹框
      previewLoding: false,
      // 期望职位 - 一级类数据 - 也是所有数据
      previewLodingData_1: [],
      // 期望职位 - 二级类数据
      previewLodingData_2: [],
      // 期望职位 - 三级类数据
      previewLodingData_3: [],
      // 期望职位 - 一级类数据 - 当前id
      previewLodingData_1_positionTypeId: "",
      // 期望职位 - 二级类数据 - 当前id
      previewLodingData_2_positionTypeId: "",
      // 期望职位 - 三级类数据 - 当前id
      previewLodingData_3_positionTypeId: "",

    };
  },
  created() {
    this.$nextTick(()=>{
      this.type = this.$route.query.type
      this.row = this.$route.query.row
    })
    this.getcity();
    this.getDictionaries();
    this.getUerInfo();
    this.getPreviewLoding();
    this.getWelfareTreatmentList();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    // 福利待遇 - 获取下拉数据
    getWelfareTreatmentList(){
      this.$post("/hr/recruitment/label/enable")
          .then((res) => {
            this.welfareTreatmentList = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    // 行政区划 - 获取下拉数据
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 对应字典
    getDictionaries() {
      // 获取 - 职位有效期字典
      const list_5 = this.$setDictionary("JOB_VALIDITY_PERIOD", "list");
      // 获取 - 到岗时间字典
      const list_6 = this.$setDictionary("HR_ARRIVAL_TIME", "list");
      // 获取 - 学历字典
      const list_1 = this.$setDictionary("HR_RECRUITMENT_EDUCATION", "list");
      // 获取 - 工作经验字典
      const list_2 = this.$setDictionary("WORK_EXPERIENCE", "list");
      // 获取 - 薪酬范围字典
      const list_3 = this.$setDictionary("HR_SALARY_RANGE", "list");
      // 获取 - 年龄要求字典
      const list_4 = this.$setDictionary("AGE_REQUIREMENT", "list");
      // 获取 - 性别要求字典
      const list_7 = this.$setDictionary("SEX_REQUIREMENT", "list");
      for (const key in list_1) {
        this.educationList.push({
          id: key,
          name: list_1[key],
        });
      }
      for (const key in list_2) {
        this.workExperienceList.push({
          id: key,
          name: list_2[key],
        });
      }
      for (const key in list_3) {
        this.salaryRangeList.push({
          id: key,
          name: list_3[key],
        });
      }
      for (const key in list_4) {
        this.ageRequirementList.push({
          id: key,
          name: list_4[key],
        });
      }
      for (const key in list_5) {
        this.jobValidityPeriodList.push({
          id: key,
          name: list_5[key],
        });
      }
      for (const key in list_6) {
        this.arrivalTimeList.push({
          id: key,
          name: list_6[key],
        });
      }
      for (const key in list_7) {

        this.sexList.push({
          id: key,
          name: list_7[key],
        });
      }
    },
    // 获取登陆者信息
    getUerInfo() {
      this.$post("/sys/admin/getAdminInfo")
        .then((res) => {
          this.ruleForm.contactName = res.data.fullname || "";
          this.ruleForm.contactMobile = res.data.mobile || "";
          this.ruleForm.compName = res.data.compName || "";

          if(this.$route.query.type != 'add'){
            //如果不是添加需要回显数据
            let params = {
              recruitmentId: this.$route.query.row.recruitmentId
            }
            this.$post("/biz/recruitment/getInfo",params)
                .then((res) => {
                  // console.log()
                  this.ruleForm = {
                    ...res.data
                  }
                  this.ruleForm.welfareTreatment = res.data.welfareTreatment.split(',');
                })
                .catch(() => {
                  return;
                });
          }

        })
        .catch(() => {
          return;
        });
    },
    handlerAduit(type){
      let _this = this
      let params = {
        auditReason: this.ruleForm.auditReason,
        auditState: type,
        recruitmentId: this.row.recruitmentId,
      }
      if(type == '30'){
        params.auditReason = ''
        this.$post("/biz/recruitment/audit", params).then(
            (res) => {
              if (res.status == 0) {
                this.$message({
                  type: "success",
                  message: "审核成功！",
                });
                let timer;
                timer = setTimeout(() =>{
                  _this.goBack()
                },1500)

              }
            }
        );
      }else{
        // this.$refs.ruleForm.validate((valid) => {
        //   if (valid) {
        if(this.ruleForm.auditReason){
          this.$post("/biz/recruitment/audit", params).then(
              (res) => {
                if (res.status == 0) {
                  this.$message({
                    type: "success",
                    message: "审核成功！",
                  });
                  let timer;
                  timer = setTimeout(() =>{
                    _this.goBack()
                  },1500)

                }
              }
          );
        }else{
          this.$message.error("请输入驳回理由！");
          return;
        }


        //   }
        // })
      }


    },

    // 点击 - 期望职位
    inputpositio(){
      this.previewLoding = true;
      // 期望职位 - 二级类数据
      this.previewLodingData_2= []
      // 期望职位 - 三级类数据
      this.previewLodingData_3= []
      // 期望职位 - 一级类数据 - 当前id
      this.previewLodingData_1_positionTypeId= ""
      // 期望职位 - 二级类数据 - 当前id
      this.previewLodingData_2_positionTypeId= ""
      // 期望职位 - 三级类数据 - 当前id
      this.previewLodingData_3_positionTypeId= ""
    },
    // 获取 - 期望职位：但只取当前第一组数据
    getPreviewLoding() {
      this.$post("/hr/talent/hr-position-type/queryAll")
          .then(res => {
            this.previewLodingData_1 = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    // 点击 - 职业分类
    selectpreview(type,item,positionTypeId){
      if(type == 1) {
        this.previewLodingData_1_positionTypeId = positionTypeId;
        this.previewLodingData_2 = item.children;
        this.previewLodingData_3 = [];
      }
      if(type == 2) {
        this.previewLodingData_2_positionTypeId = positionTypeId;
        this.previewLodingData_3 = item.children;
      }
      if(type == 3) {
        this.previewLodingData_3_positionTypeId = positionTypeId;
        this.ruleForm.positionId = positionTypeId;
        this.ruleForm.positionName = item.positionTypeName;
        this.previewLoding = false;
      }
    },

    // 确认提交
    submitForm(formName) {
      let _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = ''
          let params = {
            ...this.ruleForm
          }
          params.welfareTreatment = this.ruleForm.welfareTreatment.toString()
          console.log(params);
          if(this.type == 'add'){
            url = "/biz/recruitment/save"
          }
          if(this.type == 'edit'){
            url = "/biz/recruitment/update"
            params.recruitmentId = this.row.recruitmentId
          }
          this.$post(url, params).then(
            (res) => {
              if (res.status == 0) {
                this.$message({
                  type: "success",
                  message: "填报成功！",
                });
                let timer;
                timer = setTimeout(() =>{
                  _this.goBack()
                },1500)
              }
            }
          );
        }
      });
    },
    goBack(){
      // debugger
      this.$router.back()
      // this.$router.go(-1)
      // this.$router.push({
      //   path: "/web/userFeedback/advertisementList",
      //   query: {
      //     refresh: true
      //   },
      // });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          // this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-form {
  width: 500px;
  margin: auto;
}
//岗位(期望职位)
.zjzw {
  height: 450px;
  display: flex;
  justify-content: space-between;
  > div {
    overflow-y: auto;
    overflow-x: hidden;
    width: 33.33%;
    margin-right: 4%;
    > div {
      line-height: 26px;
      padding-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
.ts_1 {
  border-left: 4px solid #6383f9;
  padding-left: 4px !important;
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #b7c6fd);
}

//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
  background-color: #eee;
  border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
/deep/.el-pager li{
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
  background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
/deep/.el-pager li.active+li{
  border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
  border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
  //color: #2878FF;
  font-size: 14px!important;
}
/deep/.bgc-bv{
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
/deep/.el-table th.el-table__cell>.cell{
  font-size: 14px!important;
  font-weight: 400!important;
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
  border-color: #2878FF !important;
}
.fl{
  float: left;
}
.colorPoint{
  float: left;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-top: 14px;
  margin-right: 10px;
}
</style>
